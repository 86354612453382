<template>
  <div>
    <main-nav :activeIndex="activeIndex"></main-nav>
    <div class="solution-header-container">
      <div class="solution-header-text">
        <h1 data-caption-delay="0" class="wow fadeInLeft">整车测试</h1>
        <p data-caption-delay="100" class="wow fadeInRight">整车电子电气测试工程服务是东信创智咨询服务事业部面向国内外整车厂及零部件供应商提供的一款最为贴心的服务产品</p>
        <div class="solution-text-button wow fadeInUp" @click="linkUrl(chatUrl)"><div>立即咨询</div></div>
      </div>
    </div>
    <section class="solution-intros wow fadeInLeft">
      <div class="container">
        <div class="normal-title">服务介绍</div>
        <div class="all-intro">
          <el-row>
            <el-col :span="24">
              <p class="intro-detail">
                整车电子电气测试工程服务是东信创智咨询服务事业部面向国内外整车厂及零部件供应商提供的一款最为贴心的服务产品。
                当前国内各大主机厂，包括异军突起的新造车势力都在不遗余力的频频推出各自新车型、新技术，使汽车更加电动化、智能化及集成化。
                提高产品的质量，合理的降低成本，不断的集成新技术，是各大主机厂提高核心竞争力的有效途径，而提升产品质量不仅需要设计优化，
                更需要贯穿整个开发周期的整车电子电气测试，以降低故障率，提高整车功能可靠性，因此整车电子电气测试作为重要的手段，在各整车厂或供应商的开发项目中起着至关重要的作用。
              </p>
            </el-col>
          </el-row>
        </div>
      </div>
    </section>
    <section class="vehicle-solution-sections wow fadeInRight">
      <div class="container">
        <div class="normal-title">服务范围</div>
        <div class="tab-container">
          <el-tabs :tab-position="isMobile ? 'top' : 'left'" :class="isMobile ? 'tab-top' : ''">
            <el-tab-pane label="用户管理">
              <p class="tab-inner-intro">东信创智可提供以功能为中心，依附整车项目主计划及功能需求，将整车电气功能分阶段集成，制定并实施多阶段、多种类集成验证的测试流程。东信创智测试团队可以融入到主机厂或供应商的项目中，同客户的开发团队共同完成整车项目开发验证，同时还可以根据不同主机厂或供应商的需求和实际情况帮助其建立专属的集成测试管理流程、问题管理流程、软件管理流程等，并提供相应的文件指导规范。</p>
            </el-tab-pane>
            <el-tab-pane label="配置管理">
              <p class="tab-inner-intro">网络及诊断测试主要包括测试规范、测试用例及测试脚本开发，部件测试，台架测试及实车测试。从总线类型划分，可分为CAN总线测试、LIN总线测试及以太网总线测试等。针对网络自动化测试，东信创智可以基于客户的不同需求，为客户定制自动化测试脚本及测试工具链等服务。</p>
            </el-tab-pane>
            <el-tab-pane label="角色管理">
              <p class="tab-inner-intro">功能测试主要包括测试用例开发、台架测试及实车测试。其中台架测试包括基于不同功能域的子系统台架测试和整车Labcar台架测试，实车测试包括实车功能测试及实车道路场景测试。东信创智可以帮助客户量身定做不同的测试方案，搭载不同的台架或实车开展功能测试。</p>
            </el-tab-pane>
            <el-tab-pane label="东信创智实验室测试">
              <p class="tab-inner-intro">为了满足主机厂和供应商测试需求，东信创智建立了本地独立的测试实验室，针对部件级测试，东信创智拥有丰富的测试设备及优秀的测试人员，客户可完全放心将被测样件交给东信测试团队，经过多次且快速迭代测试，最终提交给客户满足要求的测试报告和问题报告。</p>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </section>
    <section class="vehicle-solution-sections wow fadeInLeft">
      <div class="container">
        <div class="normal-title">服务内容</div>
        <div class="tab-container">
          <el-tabs :tab-position="isMobile ? 'top' : 'left'" :class="isMobile ? 'tab-top' : ''">
            <el-tab-pane label="测试流程体系搭建">
              <div class="tab-detail-container">
                <h3>测试流程整车功能集成与验证流程</h3>
                <p class="tab-inner-intro">从项目前期介入，参与功能划分，制定清晰的功能执行计划，将整车功能分配到不同测试阶段集成，在不同的阶段，收集满足计划要求的被测试样件，搭建测试环境，开展有目的性，有针对性的整车功能集成测试。</p>
                <img src="../../../assets/images/solutions/vehicle-test-01.jpeg" style="width: 90%" alt="">
                <p class="img-descr">整车功能集成与验证流程</p>
                <h3>测试流程</h3>
                <p class="tab-inner-intro">测试任务下发后，需要制定测试方案、进行测试开发、开展测试执行、编写测试报告，最终汇总进行问题管理，确保每一个问题得到确认及解决。</p>
                <img src="../../../assets/images/solutions/vehicle-test-02.jpg" alt="">
                <p class="img-descr">测试流程</p>
                <h3>问题管理</h3>
                <p class="tab-inner-intro">从问题的发现到确认解决，关注问题流转的每一个环节，严紧对待每一个问题的提出和关闭。建立问题重要度机制和问题分类机制，帮助项目管理团队做出应对措施。</p>
                <img src="../../../assets/images/solutions/vehicle-test-03.jpeg" style="width: 40%" alt="">
                <p class="img-descr">问题管理流程</p>
              </div>
            </el-tab-pane>
            <el-tab-pane label="网络及诊断测试">
              <div class="tab-detail-container">
                <el-row>
                  <el-col :span="12">
                    <h3>测试规范</h3>
                    <ul class="function-list">
                      <li>
                        CAN/CANFD/LIN网络及诊断测试规范
                        <ul>
                          <li>单部件网络及诊断测试规范</li>
                          <li>台架、实车系统测试规范</li>
                        </ul>
                      </li>
                      <li>
                        以太网测试规范
                        <ul>
                          <li>TC8 3.0测试规范</li>
                          <li>DoIP相关测试规范</li>
                          <li>台架、实车系统集测试规范</li>
                        </ul>
                      </li>
                    </ul>
                  </el-col>
                  <el-col :span="12">
                    <h3>测试脚本</h3>
                    <ul class="function-list">
                      <li>
                        CAN/CANFD/LIN网络及诊断测试脚本
                        <ul>
                          <li>CAN/CANFD通信部件测试脚本</li>
                          <li>网关路由测试脚本</li>
                          <li>网络管理测试脚本（AUTOSAR/OSEK）</li>
                          <li>BootLoader刷写测试脚本</li>
                          <li>LIN主/从节点通信测试脚本</li>
                        </ul>
                      </li>
                      <li>
                        以太网测试脚本
                        <ul>
                          <li>DoIP相关测试脚本</li>
                          <li>ETH-CAN网关路由测试脚本</li>
                          <li>车载以太网Layer1-Layer7 TC8测试脚本</li>
                        </ul>
                      </li>
                    </ul>
                  </el-col>
                </el-row>
                <el-divider></el-divider>
                <el-row>
                  <el-col :span="12">
                    <h3>部件测试</h3>
                    <ul class="function-list">
                      <li>CAN/CANFD/LIN网络及诊断测试</li>
                      <li>以太网TC8及DoIP相关测试</li>
                    </ul>
                  </el-col>
                  <el-col :span="12">
                    <h3>台架测试</h3>
                    <ul class="function-list">
                      <li>CAN/CANFD/LIN系统网络测试</li>
                      <li>以太网系统网络测试</li>
                      <li>故障注入</li>
                    </ul>
                  </el-col>
                </el-row>
                <el-divider></el-divider>
                <el-row>
                  <el-col :span="12">
                    <h3>实车测试</h3>
                    <ul class="function-list">
                      <li>CAN/CANFD/LIN系统网络测试</li>
                      <li>以太网系统网络测试</li>
                      <li>故障注入</li>
                    </ul>
                  </el-col>
                </el-row>
              </div>
            </el-tab-pane>
            <el-tab-pane label="功能测试">
              <h3>测试用例开发</h3>
              <p class="tab-inner-intro">整车功能测试用例编写的主要依据包括：整车电气功能清单、系统功能规范、各控制器功能规范、测试场景及编写人的测试经验。在掌握了基本的设计原则之后，按照正确的方法，最终编写出完整且高效的测试用例。</p>
              <p class="tab-inner-intro">汽车功能测试用例经常用到的设计方法有5种。分别为等价类划分法、边界值分析法、因果图/判定表法、错误推测法及流程分析法。</p>
              <h3>台架测试</h3>
              <h4>整车Labcar台架测试</h4>
              <p class="tab-inner-intro">Labcar测试是以功能为中心，将整车所有控制器、线束及真实负载集成在一起进行虚拟整车台架测试，是必不可少的整车系统测试手段。</p>
              <img src="../../../assets/images/solutions/vehicle-test-04.jpeg" style="width: 50%;" alt="">
              <p class="img-descr">Labcar台架</p>
              <h4>子系统台架</h4>
              <p class="tab-inner-intro">该子系统测试是将不同子系统的控制器与负载搭载在一起进行的台架测试，测试中相关的接口信号利用仿真实现，配合整车Labcar完成整车功能测试。</p>
              <div style="display: flex;align-items: center;justify-content: center">
                <img src="../../../assets/images/solutions/vehicle-test-05.jpg" style="width: 200px;" alt="">
                <img src="../../../assets/images/solutions/vehicle-test-06.jpg" style="width: 200px" alt="">
              </div>
              <p class="img-descr">子系统台架</p>
              <h3>实车功能测试</h3>
              <p class="tab-inner-intro">基于整车最新状态，定期更新整车状态，在实车上以客户角度进行的静态和动态功能测试，测试的对象是面向整车所有不同域的用户功能，以动态为主，与台架分工合作，最终完成整车用户功能测试。</p>
              <img src="../../../assets/images/solutions/vehicle-test-07.jpg" style="width: 70%;" alt="">
              <p class="img-descr">功能测试内容</p>
              <h3>实车道路场景测试</h3>
              <p class="tab-inner-intro">除了基于功能规范开展正向功能测试外，也要考虑在不同环境、不同场景下的实车道路测试，综合各种因素、各种可能情况，多方面开展整车功能测试。</p>
              <img src="../../../assets/images/solutions/vehicle-test-08.jpg" style="width: 70%;" alt="">
              <p class="img-descr">实车测试场景</p>
            </el-tab-pane>
            <el-tab-pane label="实验室部件测试">
              <ul class="function-list">
                <li>Ethernet物理层测试-PMA测试</li>
                <li>Ethernet物理层测试-IOP测试</li>
                <li>Ethernet数据链路层测试-Switch性能与功能测试</li>
                <li>Ethernet网络层传输层测试-TCP/IP协议一致性测试</li>
                <li>Ethernet应用层测试-SOME/IP一致性测试</li>
                <li>Ethernet应用层测试-DOIP协议测试</li>
                <li>Ethernet应用层测试-网关路由测试</li>
                <li>Ethernet应用层测试-UDSOnIP测试</li>
                <li>CAN/CANFD/LIN网络及诊断测试</li>
                <li>ECU I/O功能测试</li>
              </ul>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </section>
    <section class="vehicle-solution-sections wow fadeInRight">
      <div class="container">
        <div class="normal-title">优势亮点</div>
        <div class="all-function">
          <ul class="priority-list">
            <li>东信创智拥有一套完整的整车功能开发与验证流程，测试流程体系健全，且具有灵活定制的特点，为客户量身定做</li>
            <li>测试团队的主要成员均来源于主机厂或供应商，了解主机厂或供应商开发流程，熟悉零部件及整车测试流程，完全拥有站在客户角度制定测试方案、开展测试的能力</li>
            <li>目前测试团队掌握的测试范围广泛，测试种类多样，测试内容丰富，即包括传统的基于CAN/CANFD/LIN总线测试，也包括基于以太网总线的测试。网络测试基本实现自动化，测试效率高</li>
            <li>测试均基于Vector、思博伦、是德、罗德等汽车测试行业认可品牌开展，测试可信度高</li>
          </ul>
        </div>
      </div>
    </section>
    <section class="vehicle-solution-sections wow fadeInLeft">
      <div class="container">
        <div class="normal-title">应用领域</div>
        <div class="all-function">
          <ul class="app-range">
            <li>
              <span>对象</span>
              <div>面向主机厂、供应商</div></li>
            <li>
              <span>范围</span>
              <div>提供单件、子系统、整车电子电气相关的网络、诊断及功能测试。</div>
            </li>
          </ul>
        </div>
      </div>
    </section>
    <bzInfo style="margin-top: 0"/>
    <main-footer></main-footer>
  </div>
</template>

<script>
import mainNav from '@/components/MainNav'
import mainFooter from '@/components/MainFooter'
import bzInfo from '@/components/BzInfo'
export default {
  name: "vehicleTest",
  components: {
    mainNav,
    mainFooter,
    bzInfo
  },
  data() {
    return {
      activeIndex: '2-5',
      chatUrl:"https://doc.weixin.qq.com/forms/AOEAXQcgAAkAe4APAaVAD0jHqcmrcFRKf",
      isMobile: false,
    }
  },
  mounted() {
    document.title = "整车测试 - 解决方案 - 东信创智"
    new this.$wow.WOW().init();
    this.isMobile = document.documentElement.clientWidth < 1200
    window.onresize = () => {
      return (() => {
        this.isMobile = document.documentElement.clientWidth < 1200;
      })();
    }
  },
  methods: {
    linkUrl(url){
      window.open(url,'_blank') // 在新窗口打开外链接
      // window.location.href =this.indexro;  //在本页面打开外部链接
    },
  }
}
</script>

<style scoped>

</style>
